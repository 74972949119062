.EditFormModelContainer{
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.2);
}

.EditFormModelWrapper{
    background-color: white;
    width: 90%;
    padding: 10px;
    border-radius: 10px;
}
.EditFormModelHeading h3{
    font-size: 24px;
    text-align: center;
}
.EditFormModelFormWrapper{
    margin-top: 20px;
}