.dropdown {
  position: relative;
  width: 100%;
}

.dropdown label {
  color: black;
}

.dropdown button {
  width: 100%;
  border-radius: 8px;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  color: #393938;
  cursor: pointer;
  border: 2px solid #dddbdb;
  background-color: #fff;
  position: relative;
  text-align: left;
}

.dropdown button:focus {
  outline: 2px solid black;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 5%;
  z-index: 1;
  list-style-type: none;
  width: 90%;
  background-color: #fff;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 150px;
  overflow-y: auto;
}

.dropdown-menu li {
  padding: 10px;
  font-size: 14px;
  color: #393938;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown button svg {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
