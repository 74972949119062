@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:wght@700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

select:required:invalid {
  color: #bdbdbd;
}

option[value=""][disabled] {
  display: none;
}

option {
  color: black;
}

@media (max-width: 800px) {
  .form_wrapper {
    border-radius: 40px 40px 0px 0px;
    position: absolute;
    background-color: white;
    z-index: 9999;
    top: 25%;
  }

  .change_mobile {
    width: 100%;
  }
}